<template>
    <main>
        <div class="modal fade" id="modalFormularios" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Formulario</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-row">
                        <div class="form-group col-lg-12 col-md-12 col-sm-12">
                            <label class="form-label">Nombre</label>
                            <input type="text" class="form-control" v-model="formulario.nombre" :class="{'is-invalid':$v.formulario.nombre.$error && $v.formulario.nombre.$dirty}">
                        </div>
                        <div class="form-group col-lg-12 col-md-12 col-sm-12">
                            <label class="form-label">Codigo</label>
                            <input type="text" class="form-control" v-model="formulario.codigo" :class="{'is-invalid':$v.formulario.codigo.$error && $v.formulario.codigo.$dirty}">
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary btn-sm" @click="save();">Guardar<i class="fa fa-save fa-fw"></i></button>
                    <button type="button" class="btn btn-light btn-sm" @click="cerrar();">Cerrar<i class="fa fa-times-circle fa-fw"></i></button>
                </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>

import formulariosService from "../../../../services/formulariosService";
//import {isEmpty} from "lodash";
import Toast from '../../../../components/common/utilities/toast';
import {required} from "vuelidate/lib/validators";
import $ from 'jquery';
export default {
    data () {
        return {
            formulario : {
                id : '',
                nombre : '',
                codigo: '',
            },
            type: 'store',
        }
    },
    validations (){
        return {
            formulario : {
                nombre : {required},
                codigo : {required},
            },
        }
    },
    methods : {
        async save(){
            try{
                
                this.$v.formulario.$touch();

                if(this.$v.formulario.$invalid){
                    return
                }

                this.LoaderSpinnerShow();

                if(this.type==='store'){
                    await formulariosService.store(this.formulario);
                    this.type='store';
                    this.limpiarCampos();
                }else if(this.type==='edit'){
                    await formulariosService.update(this.formulario);
                    this.limpiarCampos();
                    this.type='store';
                }
                
                this.LoaderSpinnerHide();

                Toast.fire({
                    icon: 'success',
                    title: 'Datos guardados con exito'
                });
                this.$emit('cargar-formularios');
                
                $('#modalFormularios').modal('hide');

            }catch(err){
                console.error(err);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        },
        async limpiarCampos(){
            this.formulario.nombre='';
            this.formulario.codigo='';
        },
        cerrar(){
            this.limpiarCampos();
            $('#modalFormularios').modal('hide');
        },
        async edit(id){
            
            this.$nextTick(async () => {
                this.type='edit';
                $('#modalFormularios').modal('show');
                 const response = await formulariosService.show(id);
                this.formulario.id=response.data.id;
                this.formulario.codigo=response.data.codigo;
                this.formulario.nombre=response.data.nombre;
                        
            });
          
           
        }
    },
}
</script>

